import Button from "@mui/material/Button";
import {
    Document,
    Page,
    StyleSheet,
    Text,
    View,
    pdf
} from "@react-pdf/renderer";
import {  useEffect, useState } from "react";
import { UsedArticleInterface } from "../../interface/responseInterface/UsedArticleInterface";
import { Download } from "@mui/icons-material";

// Create styles
const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: "white",
    fontFamily: "Helvetica",
    fontSize: 12,
  },
  header: {
    textAlign: "center",
    marginBottom: 20,
  },
  headerContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 18,
    fontWeight: "bold",
  },
  branchText: {
    fontSize: 14,
    fontStyle: "italic",
  },
  tableContainer: {
    marginBottom: 20,
    border: "1px solid black",
    borderRadius: 4,
    overflow: "hidden",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeader: {
    fontWeight: "bold",
    textAlign: "center",
    flexDirection: "row",
    backgroundColor: "lightblue",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    border: "1px solid black",
    padding: 8,
    textAlign: "left",
    borderColor: "#000",
  },
  tableCellTestDate: {
    flex: 1,
    width: "20%",
  },
  tableCellSterilizer: {
    flex: 2,
    width: "35%",
  },
  tableCellResults: {
    flex: 1,
    width: "15%",
  },
  tableCellColspan: {
    flex: 2,
    width: "35%",
  },
  passStyle: {
    color: "green",
  },
  failStyle: {
    color: "red",
  },
  footer: {
    textAlign: "center",
    marginTop: 30,
    fontSize: 12,
    fontStyle: "italic",
    color: "#555",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

// Define prop types
interface MyDocumentProps {
  data: UsedArticleInterface[];
  branchTitle: string | null;
}

// Function to split data into pages
const splitDataIntoPages = (
  data: UsedArticleInterface[],
  rowsPerPage: number
) => {
  const pages = [];
  for (let i = 0; i < data.length; i += rowsPerPage) {
    pages.push(data.slice(i, i + rowsPerPage));
  }
  return pages;
};

const getInitials = (fullName: string) => {
  const nameParts = fullName.split(" ");
  const initials = nameParts.map((part) => part.charAt(0)).join("");
  return initials.toUpperCase();
};

// Create a Document Component
const MyDocument = ({ data, branchTitle }: MyDocumentProps) => {
  const rowsPerPage = 12; // Adjust this value based on your content and page size
  const pages = splitDataIntoPages(data, rowsPerPage);

  return (
    <Document>
      {pages.map((pageData, pageIndex) => (
        <Page
          key={pageIndex}
          orientation="landscape"
          size="A4"
          style={styles.container}
        >
          <View style={styles.header}>
            <View style={styles.headerContent}>
              <Text style={styles.headerText}>Used Articles List</Text>
              <Text style={styles.branchText}>
                {branchTitle ? branchTitle : ""}
              </Text>
            </View>
          </View>

          <View style={styles.tableContainer}>
            <View style={styles.table}>
              {/* Table Header */}
              <View style={styles.tableHeader}>
                <Text style={[styles.tableCell, styles.tableCellTestDate]}>
                  Used Date
                </Text>
                <Text style={[styles.tableCell, styles.tableCellSterilizer]}>
                  Article Name
                </Text>
                <Text style={[styles.tableCell, styles.tableCellResults]}>
                  Control No.
                </Text>
                <Text style={[styles.tableCell, styles.tableCellSterilizer]}>
                 Autoclave Name
                </Text>
                <Text style={[styles.tableCell, styles.tableCellResults]}>
                Cycyle No.
                </Text>
                <Text style={[styles.tableCell, styles.tableCellResults]}>
                  Used By
                </Text>
                <Text style={[styles.tableCell, styles.tableCellResults]}>
                  Loaded on
                </Text>
              </View>

              {/* Table Rows */}
              {pageData.map((item, index) => (
                <View key={index} style={styles.tableRow} wrap={false}>
                  <Text style={[styles.tableCell, styles.tableCellTestDate]}>
                    {item.date_used.split("T")[0]}
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellSterilizer]}>
                    {item.type}
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellResults]}>
                    {item.control_no}
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellSterilizer]}>
                    {item.autoclave_name}
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellResults]}>
                    {item.cycle_no}
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellResults]}>
                    {getInitials(item.used_by.toString())}
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellResults]}>
                    {item.load_finished_at.split("T")[0]}
                  </Text>
                </View>
              ))}
            </View>
          </View>

          <View style={styles.footer}>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </View>
        </Page>
      ))}
    </Document>
  );
};

const ExportPDFButton = ({ data, branchTitle }: MyDocumentProps) => {
  const [loading, setLoading] = useState<boolean | null>(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const generatePDF = async () => {
    setPdfUrl(null);
    setLoading(true);
    const blob = await pdf(
      <MyDocument data={data} branchTitle={branchTitle} />
    ).toBlob();
    if (blob) {
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
      setLoading(false);
      document.getElementById("downloadLink")?.click();
    }
  };

  useEffect(() => {
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [pdfUrl]);

return (
    <>
        {!pdfUrl && (
            <Button onClick={generatePDF} variant="contained" loading={loading} loadingPosition="start">
                {!loading ? "Export to PDF" : "Preparing document..."}
            </Button>
        )}
        {pdfUrl && (
            <Button startIcon={<Download/>} href={pdfUrl} variant="contained" download="Used_Articles_Report.pdf" id="downloadLink">
                Download now
            </Button>
        )}
    </>
);
};

export default ExportPDFButton;
