import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import DynamicTable from "../../components/table/DynamicTable";
import UsedArticleInfoDialog from "./UsedArticleInfoDialog";
import useSessionData from "../../hooks/useSessionData";
import { Column, Data, TableActions } from "../../interface/TableInterface";
import { UsedArticleResponseInterface } from "../../interface/responseInterface/UsedArticleInterface";
import { RootState } from "../../stores/store";
import { UsedArticleInterface } from "../../interface/responseInterface/UsedArticleInterface";
import { useMessageModal } from "../../hooks/useMessageModal";
import { Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ExportPDFButton from "./ExportUsedArticles";

const columns: Column[] = [
  { id: "id", label: "#", numeric: false },
  { id: "article_name", label: "Article Name", numeric: false },
  { id: "control_no", label: "Control Number", numeric: false },
  { id: "used_by", label: "Used By", numeric: false },
  { id: "used_date", label: "Used Date", numeric: false },
];

const UsedArticles = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const [response, setResponse] = useState<Data[]>([]);
  const [responseData, setResponseData] = useState<UsedArticleInterface[]>([]);
  const [branchName, setBranchName] = useState<string | null>(null);
  const clientInfo = useSelector((state: RootState) => state.client.clientInfo);

  useEffect(() => {
    if (clientInfo && clientInfo.length > 0) {
      setBranchName(clientInfo[0].branch_name);
    }
  }, [clientInfo]);

  const [selectedArticle, setSelectedArticle] =
    useState<UsedArticleInterface | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );

    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    const getServiceLog = async () => {
      const branchQuery: string = `ap.branch_id=${currentBranch}`;

      // Date query initialization
    let dateQuery = "";
    // Format the start and end dates
        const startDateFormatted = startDate
          ? startDate.format("YYYY-MM-DD HH:mm:ss.SSSSSS")
          : "1970-01-01 00:00:00.000000";
    
        const endDateFormatted = endDate
          ? endDate.format("YYYY-MM-DD HH:mm:ss.SSSSSS")
          : moment().format("YYYY-MM-DD HH:mm:ss.SSSSSS");
    
        // Append date range to the query if dates are provided
        if (startDate || endDate) {
          dateQuery = `&ap.load_started_at[gte]=${startDateFormatted}&ap.load_started_at[lte]=${endDateFormatted}`;
        }

        // Full query string, ensuring no extra `&` or `AND`
    const fullQuery = `${branchQuery}${dateQuery}`;
      try {
        const { data } = await axios.get<UsedArticleResponseInterface>(
          `${API_URL}web-reports/usedarticlelog?${fullQuery}`,
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );
        setResponseData(data.res);
        const mappedData: Data[] = data.res.map((item, index) => ({
          id: item.id,
          article_name: item.type,
          control_no: item.control_no,
          used_by: item.used_by,
          used_date: item.date_used.split("T")[0],
        }));
        setResponse(mappedData);
      } catch (error) {
        handleOpenModal("error", "Something went wrong while fetching data!");
        return;
      }
    };

    getServiceLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentBranch, startDate, endDate
  ]);

  const handleViewDetails = (index: string) => {
    // Access the article from responseData using the index
    const article = responseData.find((item) => item.id.toString() === index);
    if (article) {
      setSelectedArticle(article); // Set the selected article to state
      setDialogOpen(true); // Open the dialog
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedArticle(null);
  };


  const handleStartDateChange = (newValue: Dayjs | null) => {
    if (newValue && endDate && newValue.isAfter(endDate)) {
      alert("Start date cannot be greater than end date.");
      resetDates();
    } else {
      setStartDate(newValue);
    }
  };

  const handleEndDateChange = (newValue: Dayjs | null) => {
    if (newValue && startDate && newValue.isBefore(startDate)) {
      alert("End date cannot be less than start date.");
      resetDates();
    } else {
      setEndDate(newValue);
    }
  };

  const resetDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const rowActions: TableActions[] = responseData.map((row) => ({
    id: `view_${row.id}`,
    label: "View Details",
    onClick: () => handleViewDetails(row.id.toString()),
  }));

  return (
    <>
      <h4>Used Articles</h4>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my:2
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </Box>
          {/* ExportPDFButton will always generate a new PDF based on the updated responseData */}
          <ExportPDFButton data={responseData} branchTitle={branchName} />
        </Box>
      </LocalizationProvider>
      <ModalComponent />
      <DynamicTable headCells={columns} rows={response} actions={rowActions} />
      {selectedArticle && (
        <UsedArticleInfoDialog
          open={dialogOpen}
          articleInfo={selectedArticle}
          parentCallback={handleCloseDialog}
        />
      )}
    </>
  );
};

export default UsedArticles;
