import moment from "moment";

export function formatTime(totalTime: number) {
  const duration = moment.duration(totalTime, "seconds");
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let formattedTime = "";

  if (hours > 0) {
    formattedTime += `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  } else if (minutes > 0) {
    formattedTime += `00:${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;
  } else {
    formattedTime += `00:00:${String(seconds).padStart(2, "0")}`;
  }

  return formattedTime;
}
